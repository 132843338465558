import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import { Select, FormControl, InputLabel, styled } from "@mui/material";
import { useGridApiContext } from "@mui/x-data-grid";
import usePreventScrollOnFocus from "./PreventScrollOnFocus";
import InputBase from "@mui/material/InputBase";

const StyledSelectField = styled(InputBase)(({ theme, error }) => ({
  "& .MuiInputBase-input": {
    border: `1.5px solid ${error ? theme.palette.primary.main : theme.palette.secondary.medium}`,
    padding: "4px 8px",
    "&:focus": {
      border: `1.5px solid black !important`,
      borderRadius: ".3rem",
      color: "#000",
    },
  },
}));

const InputEditTableDropDown = ({
  value,
  memu,
  field,
  id,
  disabledd = false,
  setValue = undefined,
  className,
  required,
  placeholder,
  defaultValue = "",
  isShow = true,
  autoFocus = false,
  isCurrency = false,
  onSearchAdd = false,
  // handleValueChange,
  
}) => {
  // const { id, value, field, hasFocus } = props;
  const apiRef = useGridApiContext();
  const ref = React.useRef();
  const preventScrollOnFocus = usePreventScrollOnFocus();

  const [isSmallScreen, setIsSmallScreen] = useState(window.innerHeight <= 740);

  useEffect(() => {
    // const handleResize = () => {
    //   setIsSmallScreen(window.innerHeight <= 740);
    // };

    // window.addEventListener("resize", handleResize);

    // return () => {
    //   window.removeEventListener("resize", handleResize);
    // };
  }, []);

  const handleValueChange = event => {
    const newValue = event.target.value; // The new value entered by the user
    apiRef.current.setEditCellValue({ id, field, value: newValue });
    if (setValue) setValue(newValue);

  };

  return (
    isShow && (
      <div style={{ margin: "auto", width: "90%" }}>
        <FormControl style={{ width: "100%" }}>
          <InputLabel
            id="test-select-label"
            shrink={false}
            size="small"
            variant="outlined"
            style={{
              left: isCurrency ? "10%" : "40px",
              top: "50%",
              transform: "translate(-50%, -50%)",
            }}>
            {memu ? (!memu?.map(item => item.key).includes(value) ? placeholder : "") : placeholder}
          </InputLabel>
          <Select
            ref={ref}
            sx={{
              minWidth: "100%",
              backgroundColor: "white",
              "&:focus": {
                border: "1px solid #000 !important",
              },
            }}
            value={value}
            autoFocus={autoFocus}
            onChange={handleValueChange}
            onFocus={preventScrollOnFocus}
            disabled={disabledd}
            className={className}
            required={required}
            MenuProps={{
              PaperProps: {
                style: {
                  overflowY: "scroll",
                  maxHeight: `${memu?.length > 30 ? "40%" : "60%"}`,
                  transform: onSearchAdd ? (isSmallScreen ? "translateY(50%)" : null) : null,
                },
              },
              onFocus: preventScrollOnFocus,
              disableScrollLock: true,
            }}
            input={<StyledSelectField />}>
            <MenuItem value={defaultValue}>
              <span>{placeholder}</span>
            </MenuItem>
            {
              memu && memu.map((val, index) => (
                <MenuItem key={index} value={val.key}>
                  {val.value}
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>
      </div>
    )
  );
};

export default InputEditTableDropDown;
