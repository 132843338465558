import React from "react";
import { Box, Grid } from "@mui/material";
import { DataGridTable, InputButton } from "components/UI";
//Utils
import { findObject, responseErrors, responseSuccess } from "utils";
import { transformSubScreenData } from "../../../hooks/tranformData";
//Service
import {
  useExportInvoiceCreateDetailVanningResultSubScreenMutation,
  useExportInvoiceEditDetailVanningResultSubScreenMutation,
  useExportInvoiceSearchSubScreenMutation,
} from "shared/services/invoice";
//Type
import { ModeAction } from "state/enum";
import { COMMON_ENUM, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { useConfirmDialogContext } from "context/confirmDialogContext";
import { DEFAULT_STATE_WDN95011 } from "pages/Invoice/ExportInvoiceCreationMaintenanceScreen/constants/constants";
import { useLoadingContext } from "shared/contexts/LoadingContext";
export default function TableSection(props) {
  const {
    mode,
    setMode,
    rows,
    setRows,
    columns,
    form,
    setForm,
    setOnSearch,
    refreshRowsTable,
    dataList: { importerData, consigneeList, brokerList, expData, shipCarrierData, shipmentEtdData },
    setMsg: { setMsgAlert, setMsgError },
    rowSelection: { rowModesModel, setRowModesModel, rowSelectionModel, setRowSelectionModel },
    pagination: { pagination, setPagination, pageNumber, setPageNumber },
    setOnOpen,
    alertMain,
    refreshRowsTableMain,
    isEdit,
  } = props;

  const confirmDialogCtx = useConfirmDialogContext();
  const { startLoading, stopLoading } = useLoadingContext();

  const searchExportInfo = useExportInvoiceSearchSubScreenMutation();
  // TODO: API create
  const createSubInvoice = useExportInvoiceCreateDetailVanningResultSubScreenMutation();
  const updateSubInvoice = useExportInvoiceEditDetailVanningResultSubScreenMutation();

  const handleChangePagination = async (event, value) => {
    try {
      setPageNumber(value);
      const body = {
        dataOwner: userProfile.dataOwner, // TODO: mock constants here
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: !form?.impCompanyId
          ? ""
          : findObject({
              data: importerData,
              value: form?.impCompanyId,
              property: "companyId",
              field: "companyAbbr",
            }),
        importerId: form?.impCompanyId,
        exporterCd: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCd",
            }),
        invoiceOrderType: form?.invType,
        exporterCompanyAbbr: !form?.exporterPlantId
          ? ""
          : findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            }),
        nonComInvHId: form?.nonCommInvHId,
        currencyCd: form?.currencyCd ?? "",
        parkingMonth: form?.parkingMonth ?? "",
        vanningPlant: form?.vanningPlant ?? "",
        brokerCompanyId: form?.expBrokerCompanyId ?? "",
        loginUserCompanyAbbr: userProfile.comAbbr, // TODO: mock constants here
        actionType: COMMON_ENUM.SC_MODE_SEARCH,
        [PAGINATION.PAGE_NUMBER]: value,
      };
      startLoading();
      const searchData = await searchExportInfo(body);
      stopLoading();
      const data = transformSubScreenData(searchData?.result?.data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setRows(data);
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError([`${errors}`]);
      window.scrollTo(0, 0);
      return;
    }
  };
  // TODO: Action Create
  const handleCreate = async newRow => {
    try {
      setMsgError([]);
      setMsgAlert([]);
      let body = {
        dataOwner: userProfile?.dataOwner,
        currencyCd: form?.currencyCd,
        expCustomsStkDt: form?.expCustomsStkDt ? form?.expCustomsStkDt : "",
        expEntryNo: form?.expEntryNo,
        etdDt: form?.etdDt ? form?.etdDt : "",
        vesselName: form?.vesselName,
        impCompanyId: form?.impCompanyId,
        exporterCompanyAbbr: form?.exporterPlantId
          ? findObject({
              data: expData,
              value: form?.exporterPlantId,
              property: "plantId",
              field: "exporterCompanyAbbr",
            })
          : "",
        expCd: form?.exporterPlantId
          ? findObject({ data: expData, value: form?.exporterPlantId, property: "plantId", field: "exporterCd" })
          : "",
        packingMonth: form?.parkingMonth,
        vanningPlant: form?.vanningPlant,
        brokerCompanyId: form?.expBrokerCompanyId,
        loginUserCompanyAbbr: userProfile.comAbbr,
        loginUser: userProfile.userId,
        carrier: form?.carrierCompanyAbbr,
        shipmentEtd: form?.shipmentEtd,
        actionType: COMMON_ENUM.SC_MODE_CREATE_INVOICE,
        isNonCommercialInvoice: form?.nonComInvFlag,
        shipmentList: newRow,
      };
      const response = await createSubInvoice(body);
      const msg = responseSuccess(response);

      setMsgAlert([msg]);
      alertMain.setMsgAlert([msg]);
      // setForm(DEFAULT_STATE_WDN95011);
      // setOnOpen({ open: false });
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      alertMain.setMsgError(errors);
      return false;
    }
  };
  // TODO: Action Update
  const handleUpdate = async row => {
    try {
      const body = {
        loginUser: userProfile.userId,
        loginUserCompanyAbbr: userProfile.comAbbr, // TODO: mock constants here
        dataOwner: form.dataOwner,
        invoiceNo: form?.invNo,
        etdDt: form?.etdDt,
        vesselName: form?.vesselName,
        importerCompanyAbbr: form?.importerCompanyAbbr,
        impCompanyId: form?.impCompanyId,
        expCd: form.exporterCd,
        exporterCompanyAbbr: form.exporterCompanyAbbr,
        nonComInvHId: form?.nonComInvHId,
        currencyCd: form?.currencyCd,
        expEntryNo: form?.expEntryNo,
        expCustomsStkDt: form?.expCustomsStkDt,
        updateDt: form?.updateDt,
        updateBy: form?.updateBy,
        brokerCompanyId: form?.expBrokerCompanyId,
        carrier: form?.carrierCompanyAbbr,
        shipmentCd: form?.shipmentCd,
        isNonCommercialInvoice: form?.nonComInvFlag,
        actionType: COMMON_ENUM.SC_MODE_SAVE_EDIT,
        shipmentList: row,
      };

      const response = await updateSubInvoice(body, form.nonComInvHId);
      const msg = responseSuccess(response);
      setMsgAlert([msg]);
      alertMain.setMsgAlert([msg]);
      window.scrollTo(0, 0);
      return true;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      return false;
    }
  };

  const processRowUpdate = async newRow => {
    let response;
    try {
      setMsgError([]);
      setMsgAlert([]);
      startLoading();
      if (newRow.isNew) {
        response = await handleCreate(newRow);
      } else {
        response = await handleUpdate(newRow);
      }
      // throw to onProcessRowUpdate is error
      if (!response) {
        stopLoading();
        return false;
      }
      console.log(mode);

      setMode(ModeAction.VIEW);
      setRowSelectionModel([]);

      // const doneRefresh = await refreshRowsTable();
      await refreshRowsTableMain();
      stopLoading();
      // if (!doneRefresh) return;
      // }
      setForm(DEFAULT_STATE_WDN95011);
      setOnOpen({ open: false });

      const updatedRow = { ...newRow, isNew: false };
      return updatedRow;
    } catch (error) {
      stopLoading();
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return false;
    }
  };

  const handleCancelClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    const confirm = await confirmDialogCtx.success({
      type: "confirmCancel",
    });
    if (!confirm) {
      return;
    }
    setMode(ModeAction.VIEW);
    setRowSelectionModel([]);
    setForm(DEFAULT_STATE_WDN95011);
    setOnOpen({ open: false });
  };
  const handleSaveClick = async () => {
    setMsgError([]);
    setMsgAlert([]);
    console.log("save");

    const saveConfirm = await confirmDialogCtx.success({ type: "confirmSave" });
    if (!saveConfirm) {
      return;
    }
    if (!isEdit) {
      console.log("create");
      let rowSelect = rows.filter(item => rowSelectionModel.includes(item.rowNumber));

      if (!rowSelect.length) {
        alert("MDN96003AERR: A record must be selected to execute Add operation.");
        return;
      }

      await handleCreate(rowSelect);
      await refreshRowsTableMain();
      setForm(DEFAULT_STATE_WDN95011);
      setOnOpen({ open: false });
    } else {
      console.log("update");
      let rowSelect = rows.filter(item => rowSelectionModel.includes(item.rowNumber));
      await handleUpdate(rowSelect);
      await refreshRowsTableMain();
      setForm(DEFAULT_STATE_WDN95011);
      setOnOpen({ open: false });
    }
  };
  const handleRowDisableSelect = params => {
    if ([ModeAction.EDIT].includes(mode)) {
      return false;
    }
  };
  return (
    <Box
      sx={{
        mt: 3,
      }}>
      <DataGridTable
        id="table-container"
        mode={mode}
        setMode={setMode}
        rows={rows}
        disableCheckBoxAll={false}
        isMultipleSelection={true}
        processRowUpdate={processRowUpdate}
        handleRowDisableSelect={handleRowDisableSelect}
        column={{
          columns,
          columnVisibilityModel: {},
        }}
        rowSelection={{
          rowModesModel,
          setRowModesModel,
          rowSelectionModel,
          setRowSelectionModel,
        }}
        pagination={{
          pagination: pagination,
          pageNumber: pageNumber,
          handleChangePagination: handleChangePagination,
        }}
        onCellClick={false}
        onCellDoubleClick={false}
        checkboxSelection={true}
      />
      <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <InputButton
          id="btn_save"
          value={"Save"}
          onClick={handleSaveClick}
          disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
        />
        <InputButton
          id="btn_cancel"
          value={"Cancel"}
          onClick={handleCancelClick}
          disabled={[COMMON_ENUM.SC_MODE_VIEW].includes(mode)}
        />
      </Grid>
      {/* <SubmitBar
        setOnSearch={setOnSearch}
        mode={onOpen?.isEdit ? ModeAction.EDIT : ModeAction.ADD}
        rows={rows}
        setMode={setMode}
        setRows={setRows}
        setRowModesModel={setRowModesModel}
        setMsgError={setMsgError}
        rowModesModel={rowModesModel}
        rowSelectionModel={rowSelectionModel}
        setRowSelectionModel={setRowSelectionModel}
      /> */}
    </Box>
  );
}
