import React, { Fragment, useEffect, useMemo, useState } from "react";
//Components
import { HeaderContentBar } from "components/Layout";
import { AlertMessage } from "components/UI";
import { Box, Grid } from "@mui/material";
import CriterialBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringSummaryScreen/components/CriterialBar";
import SubCriterialBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringSummaryScreen/components/SubCriterialBar";
import ButtonBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringSummaryScreen/components/ButtonBar";
import TableBar from "pages/Common/Monitoring/LogMonitoringScreen/LogMonitoringSummaryScreen/components/TableBar";
//Hooks
import useColumn from "./hooks/useColumn";
import useLocalStorage from "shared/hooks/useLocalStorage";
// Utils
import { messageDisplay, responseErrors } from "utils";
// Service
import {
  useLogMonitorGetModuleQuery,
  useLogMonitorGetFunctionQuery,
  useLogMonitorGetLogStatusQuery,
  useLogSummarySearchMutation,
} from "shared/services/common/monitoring";
// Type
import { API_LOG_MONITORING, DEFAULT_LOCAL_STATE, FIRST_PAGE, PAGINATION, ROW_PER_PAGE } from "shared/constants";
import { userProfile } from "constant";
import { MSG_TYPE, ModeAction } from "state/enum";
import dayjs from "dayjs";
const LogMonitoringSummaryScreen = () => {
  const functionId = "WSTD3010";
  
  const [logDetail, setLogDetail] = useLocalStorage("logDetail", DEFAULT_LOCAL_STATE);
  // const [logSummary, setLogSummary] = useLocalStorage("logSummary", DEFAULT_LOCAL_STATE_SUMMARY);
  const [rows, setRows] = useState([]);
  const [rowModesModel, setRowModesModel] = useState({});
  const [rowSelectionModel, setRowSelectionModel] = useState([]);

  const [mode, setMode] = useState(ModeAction.VIEW);

  const [onSearch, setOnSearch] = useState(false);

  const [msgError, setMsgError] = useState([]);
  const [msgAlert, setMsgAlert] = useState([]);

  const [searchForm, setSearchForm] = useState({
    [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
    [API_LOG_MONITORING.MODULE_ID]: "",
    [API_LOG_MONITORING.FUNCTION_ID]: "",
    [API_LOG_MONITORING.STATUS_ID]: "",
    [API_LOG_MONITORING.LEVEL_ID]: "",
    [API_LOG_MONITORING.USER_ID]: "",
    [API_LOG_MONITORING.APP_ID]: "",
    [API_LOG_MONITORING.DATE_FORM]: dayjs().format("DD/MM/YYYY"),
    [API_LOG_MONITORING.DATE_TO]: dayjs().format("DD/MM/YYYY"),
  });
  // pagination
  const [pagination, setPagination] = useState({});
  const [pageNumber, setPageNumber] = useState(1);

  // api
  const { data: moduleData } = useLogMonitorGetModuleQuery();
  const { data: functionData } = useLogMonitorGetFunctionQuery(searchForm?.moduleId ? searchForm?.moduleId : "all");
  
  const cdOrder = { PS: 1, I: 2, W: 3, E: 4, F: 5 };
  let { data: logStatusData } = useLogMonitorGetLogStatusQuery();
  logStatusData?.sort((a, b) => cdOrder[a.cd] - cdOrder[b.cd]);

  const searchSummary = useLogSummarySearchMutation();
  const handleTabLogDetail = row => {
  const logData = {
      dataOwner: userProfile.dataOwner,
      moduleId: row?.moduleId,
      functionId: row?.functionId,
      statusId: row?.statusId,
      userId: row?.userId,
      appId: row?.appId,
      dateFrom: row?.startDate ? row?.startDate : null,
      dateTo: row?.endDate ? row?.endDate : null,
      levelId: "",
    };
    setLogDetail(logData);
    window.open("/common/monitoring/log-monitoring-detail", "_blank");
    handleSearchData(logData);
    // setLogSummary({
    //   dataOwner: userProfile.dataOwner,
    //   moduleId: logData?.moduleId,
    //   functionId: searchForm?.functionId,
    //   statusId: searchForm?.statusId,
    //   userId: searchForm?.userId,
    //   appId: searchForm?.appId,
    //   dateFrom: !searchForm?.dateFrom ? null : searchForm?.dateFrom,
    //   dateTo: !searchForm?.dateTo ? null : searchForm?.dateTo,
    // });
  };

  // columns
  // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  const columns = useMemo(() => useColumn({ rows, mode, handleTabLogDetail }), [rows, mode]);
  const handleSearchData = async logData => {
    try {
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);
      setOnSearch(false);
      setSearchForm(prev => ({
        ...prev,
        [API_LOG_MONITORING.MODULE_ID]: logData?.moduleId,
        [API_LOG_MONITORING.FUNCTION_ID]: searchForm?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm?.statusId,
        [API_LOG_MONITORING.LEVEL_ID]: searchForm?.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? dayjs().format("DD/MM/YYYY") : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? dayjs().format("DD/MM/YYYY") : searchForm?.dateTo,
      }));

      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: searchForm?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: searchForm?.moduleId,
        funtionId: searchForm?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: searchForm?.statusId,
        [API_LOG_MONITORING.USER_ID]: searchForm?.userId,
        [API_LOG_MONITORING.APP_ID]: searchForm?.appId,
        [API_LOG_MONITORING.DATE_FORM]: !searchForm?.dateFrom ? dayjs().format("DD/MM/YYYY") : searchForm?.dateFrom,
        [API_LOG_MONITORING.DATE_TO]: !searchForm?.dateTo ? dayjs().format("DD/MM/YYYY") : searchForm?.dateTo,
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      // validate
      const searchData = await searchSummary(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item?.rowNumber,
          [API_LOG_MONITORING.APP_ID]: item?.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item?.moduleId,
          [API_LOG_MONITORING.FUNCTION_ID]: item?.functionId,
          [API_LOG_MONITORING.START_DATE]: item?.startDate,
          [API_LOG_MONITORING.END_DATE]: item?.endDate,
          [API_LOG_MONITORING.START_TIME]: item?.startDateT,
          [API_LOG_MONITORING.END_TIME]: item?.endDateT,
          [API_LOG_MONITORING.USER_ID]: item?.userId,
          [API_LOG_MONITORING.STATUS]: item?.status,
          [API_LOG_MONITORING.MESSAGE]: item?.message,
          [API_LOG_MONITORING.FUNCTION_NAME]: item?.funtionName,
          [API_LOG_MONITORING.MODULE_NAME]: item?.moduleName,
        })) ?? [];

      if (!data.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        setRows([]);
        setPagination({});
        setPageNumber(FIRST_PAGE);
        setOnSearch(false);
        return;
      }
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };
  const handleSearchDataAfterRefresh = async logData => {
    try {
      console.log("handleSearchDataAfterRefresh", logData);
      setRows([]);
      setMsgError([]);
      setMsgAlert([]);

      setSearchForm({
        [API_LOG_MONITORING.DATA_OWNER]: userProfile.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: logData?.moduleId,
        [API_LOG_MONITORING.FUNCTION_ID]: logData?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: logData?.statusId,
        [API_LOG_MONITORING.LEVEL_ID]: logData?.userId,
        [API_LOG_MONITORING.APP_ID]: logData?.appId,
        [API_LOG_MONITORING.DATE_FORM]: logData?.dateFrom ? logData?.dateFrom : dayjs().format("DD/MM/YYYY"),
        [API_LOG_MONITORING.DATE_TO]: logData?.dateTo ? logData?.dateTo : dayjs().format("DD/MM/YYYY"),
      });

      const body = {
        [API_LOG_MONITORING.DATA_OWNER]: userProfile?.dataOwner,
        [API_LOG_MONITORING.MODULE_ID]: logData?.moduleId,
        funtionId: logData?.functionId,
        [API_LOG_MONITORING.STATUS_ID]: logData?.statusId,
        [API_LOG_MONITORING.USER_ID]: logData?.userId,
        [API_LOG_MONITORING.APP_ID]: logData?.appId,
        [API_LOG_MONITORING.DATE_FORM]: logData?.dateFrom ? logData?.dateFrom : dayjs().format("DD/MM/YYYY"),
        [API_LOG_MONITORING.DATE_TO]: logData?.dateTo ? logData?.dateTo : dayjs().format("DD/MM/YYYY"),
        [PAGINATION.PAGE_NUMBER]: FIRST_PAGE,
        [PAGINATION.ROW_PER_PAGE]: ROW_PER_PAGE,
      };
      // validate
      const searchData = await searchSummary(body);
      const data =
        searchData?.result?.data?.map((item, index) => ({
          no: index + 1,
          [API_LOG_MONITORING.ROW_NUMBER]: item?.rowNumber,
          [API_LOG_MONITORING.APP_ID]: item?.aplId,
          [API_LOG_MONITORING.MODULE_ID]: item?.moduleId,
          [API_LOG_MONITORING.FUNCTION_ID]: item?.functionId,
          [API_LOG_MONITORING.START_DATE]: item?.startDate,
          [API_LOG_MONITORING.END_DATE]: item?.endDate,
          [API_LOG_MONITORING.START_TIME]: item?.startDateT,
          [API_LOG_MONITORING.END_TIME]: item?.endDateT,
          [API_LOG_MONITORING.USER_ID]: item?.userId,
          [API_LOG_MONITORING.STATUS]: item?.status,
          [API_LOG_MONITORING.MESSAGE]: item?.message,
          [API_LOG_MONITORING.FUNCTION_NAME]: item?.funtionName,
          [API_LOG_MONITORING.MODULE_NAME]: item?.moduleName,
        })) ?? [];

      if (!data?.length) {
        setMsgError([messageDisplay({ type: MSG_TYPE.ERROR, code: "MSTD0059AERR", msg: "" })]);
        return;
      }
      setRows(data);
      setPagination(searchData?.result?.pagination ?? {});
      setPageNumber(searchData?.result?.pagination?.pageNumber);
      setOnSearch(true);
      // setLogSummary(DEFAULT_LOCAL_STATE_SUMMARY);
      return;
    } catch (error) {
      const errors = responseErrors(error);
      setMsgError(errors);
      window.scrollTo(0, 0);
      return;
    }
  };

  // useEffect(() => {
  //   if (!state) {
  //     return;
  //   }
  //   // handleSearchData(state);
  //   handleSearchDataAfterRefresh(state);
  //   return;
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [state]);

  useEffect(() => {
    const unloadCallback = event => {
      event.preventDefault();
      return true;
    };
    window.addEventListener("beforeunload", unloadCallback);
    //cleanup function
    return () => window.removeEventListener("beforeunload", unloadCallback);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Fragment>
      <Box sx={{ padding: "1rem" }}>
        <HeaderContentBar title="WSTD3010 : Log Monitoring Summary Screen" />
        <AlertMessage type={"warning"} message={msgError} />
        <AlertMessage type={"success"} message={msgAlert} />
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <CriterialBar
            searchForm={searchForm}
            setSearchForm={setSearchForm}
            datalist={{ moduleData, functionData, logStatusData }}
          />
        </Grid>
        <Grid container spacing={2} sx={{ alignItems: "center" }}>
          <SubCriterialBar searchForm={searchForm} setSearchForm={setSearchForm} />
        </Grid>
      </Box>
      <Box sx={{ padding: "0 0 7px 0" }}>
        <ButtonBar
          setMode={setMode}
          setRows={setRows}
          searchForm={searchForm}
          setSearchForm={setSearchForm}
          // setLogSummary={setLogSummary}
          setLogDetail={setLogDetail}
          setOnSearch={setOnSearch}
          setRowModesModel={setRowModesModel}
          setRowSelectionModel={setRowSelectionModel}
          setMsg={{ setMsgError, setMsgAlert }}
          pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
          functionId={functionId}
        />
      </Box>
      <Box sx={{ padding: "1rem" }}>
        {/* <Grid container spacing={2}> */}
        {onSearch && (
          <TableBar
            searchForm={searchForm}
            mode={mode}
            setMode={setMode}
            onSearch={onSearch}
            rows={rows}
            setRows={setRows}
            columns={columns}
            setMsg={{ setMsgError, setMsgAlert }}
            pagination={{ pagination, setPagination, pageNumber, setPageNumber }}
            rowSelection={{ rowSelectionModel, setRowSelectionModel, rowModesModel, setRowModesModel }}
          />
        )}
        {/* </Grid> */}
      </Box>
    </Fragment>
  );
};

export default LogMonitoringSummaryScreen;
