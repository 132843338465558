import { API_EXCEL } from "shared/constants";
import { COLUMN_NAME } from "pages/Common/Monitoring/ExcelDownloadScreen/constants/table.constant";
import { TanStackCheckBox, TanStackDropDown, TanStackTextField } from "components/UI";
export default function useColumn(logOprList) {
  return [
    // Col 1
    // {
    //   accessorKey: API_EXCEL.ROW_NUMBER,
    //   header: COLUMN_NAME.NO,
    //   size: 50,
    // },
    // Col 2
    {
      accessorKey: API_EXCEL.FIELD,
      header: COLUMN_NAME.FIELD_NAME,
      size: 350,
    },
    // Col 3
    {
      accessorKey: API_EXCEL.DISPLAY_NAME,
      header: COLUMN_NAME.DISPLAY_NAME,
      size: 350,
      cell: params => {
        return (
          <TanStackTextField
            maxLength={50}
            value={params.getValue()}
            regularExp={/^[a-zA-Z0-9]*$/}
            fullWidth={true}
            {...params}
          />
        );
      },
    },
    // Col 4
    {
      accessorKey: API_EXCEL.ORDER_DISPLAY,
      size: 50,
      header: COLUMN_NAME.ORDER_DISP,
      cell: params => {
        return (
          <TanStackTextField
            maxLength={2}
            regularExp={/^[\d.]*$/}
            fullWidth={true}
            value={params.getValue()}
            {...params}
            style={{ textAlign: "end", }}
          />
        );
      },
    },
    // Col 5
    {
      accessorKey: API_EXCEL.PK,
      header: COLUMN_NAME.PK_OPTION,
      size: 50,
    },
    // Col 6
    {
      accessorKey: API_EXCEL.DATA_TYPE,
      header: COLUMN_NAME.DATE_TYPE,
      size: 120,
    },
    // Col 7
    {
      accessorKey: API_EXCEL.CRITERIA,
      header: COLUMN_NAME.CRITERIA,
      size: 350,
      cell: params => {
        return <TanStackTextField maxLength={32} value={params.getValue()} fullWidth={true} {...params} />;
      },
    },
    // Col 8
    {
      accessorKey: API_EXCEL.LOGICAL_OPERATION,
      header: COLUMN_NAME.LOGICAL_OPR,
      size: 100,
      cell: params => {
        return (
          <TanStackDropDown
            menu={logOprList?.map(val => ({
              key: val.cd,
              value: val.value,
            }))}
            defaultValue=""
            placeholder=""
            {...params}
          />
        );
      },
    },
    // Col 9
    {
      accessorKey: API_EXCEL.DISPLAY_OPTION,
      header: COLUMN_NAME.DISP_OPT,
      size: 50,
      cell: params => {
        return <TanStackCheckBox placement="top" value={params.getValue()} checked={true} {...params} />;
      },
    },
    // Col 10
    {
      accessorKey: API_EXCEL.SORT,
      header: COLUMN_NAME.SORT,
      size: 70,
      cell: params => {
        return (
          <TanStackTextField
            maxLength={2}
            value={params.getValue()}
            regularExp={/^[\d.]*$/}
            fullWidth={true}
            {...params}
          />
        );
      },
    },
  ];
}
